<template>
<v-app>
  <v-container>
    <v-row class="ma-10">
      <v-col align="center">
        <v-card class="elevation-12" max-width="500">
          <v-toolbar dark color="info">
            <v-toolbar-title>Login form</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-form @submit="login">
              <v-text-field prepend-icon="mdi-account" type="text" v-model="email" placeholder="email" required :rules="emailRules" v-on:keydown.enter.prevent="login">
              </v-text-field>
              <v-text-field prepend-icon="mdi-lock" v-model="password" type="password" placeholder="password" required>
              </v-text-field>
              <v-spacer></v-spacer>
              <v-btn class="ma-2" :disabled="!valid" color="info" @click="login">Login</v-btn>
            </v-form>

            <small class="form-text text-muted"><p>Or <a @click="reset">reset</a> if you forgot your password. </p></small>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</v-app>
</template>

<script>
import {
  mapActions
} from "vuex";

export default {
  data() {
    return {
      email: "",
      password: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      valid: true,
    };
  },
  methods: {
    ...mapActions(["resetPassword"]),
    reset() {
      if (this.email == "") {
        this.$alert("Enter your email in the email field first.");
      } else {
        this.resetPassword(this.email);
      }
    },
    login() {
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>
